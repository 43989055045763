import { EventEmitter } from '../../EventEmitter';
import { Platform, ScreenBounds } from '../types/recorder/common';
import { AppetizeApp } from '../types/app';
import { DeviceInfo } from '../../client';
import { SessionConfig } from '../../session';
import { SocketProtocol } from '../types/socket';
import { SessionEventMapper } from './event';

/**
 * Wraps an Appetize socket and maps payloads to and from the public API.
 */
export class SessionSocketMapper
    extends EventEmitter
    implements SocketProtocol
{
    platform: Platform;
    screen: ScreenBounds;
    app?: AppetizeApp;
    private _socket: SocketProtocol;

    constructor({
        socket,
        platform,
        screen,
        app,
    }: {
        socket: SocketProtocol;
        platform: Platform;
        screen: ScreenBounds;
        app?: AppetizeApp;
    }) {
        super();
        this._socket = socket;
        this.platform = platform;
        this.screen = screen;
        this.app = app;

        socket.on('*', ({ type, value }) => {
            const mapped = this.mapEmit(type, value);
            const suppressed = mapped === null;

            if (!suppressed) {
                this.handleEvent(mapped.type, mapped.value);
                this.emit(mapped.type, mapped.value);
                this.emit('*', mapped);
            }
        });
    }

    send(event: string, data?: any): Promise<void> {
        const mapped = this.mapSend(event, data);
        return this._socket.send(mapped.type, mapped.value);
    }

    disconnect(): Promise<void> {
        return this._socket.disconnect();
    }

    private handleEvent(type: string, value: any) {
        // update app, screen, platform for mappers
        switch (type) {
            case 'app':
                this.app = value;
                break;
            case 'deviceInfo': {
                const deviceInfo = value as DeviceInfo;
                if (deviceInfo?.screen) {
                    this.screen = deviceInfo.screen;
                }
                break;
            }
            case 'config': {
                const config = value as SessionConfig;
                if (config.platform) {
                    this.platform = config.platform;
                }
                break;
            }
        }
    }

    private mapEmit(type: string, value: any) {
        const eventMapper = new SessionEventMapper({
            platform: this.platform,
            screen: this.screen,
            app: this.app,
        });

        return eventMapper.toPublic(type, value);
    }

    private mapSend(type: string, value: any) {
        const eventMapper = new SessionEventMapper({
            platform: this.platform,
            screen: this.screen,
            app: this.app,
        });

        return eventMapper.toInternal(type, value);
    }
}
