import { UserSessionConfig } from './session';
import { cleanObject } from './util';

/**
 * Parses user config and does any necessary transformations
 */
export function parseConfig(config?: UserSessionConfig) {
    if (!config) {
        return {};
    }

    return {
        ...cleanObject(config),
        device: (config as any).deviceType || config.device,
    };
}
