import { cleanObject } from '../../util';
import { DataParser } from '../parser';
import { Platform, ScreenBounds } from '../types/recorder/common';
import * as InternalRecorderAPI from '../types/recorder/internal';
import * as PublicRecorderAPI from '../types/recorder/public';

export class ElementMapper {
    platform: Platform;
    screen: ScreenBounds;

    constructor({
        platform,
        screen,
    }: {
        platform: Platform;
        screen: ScreenBounds;
    }) {
        this.platform = platform;
        this.screen = screen;
    }

    private pixelToDip(value: number) {
        return value / (this.screen.devicePixelRatio || 1);
    }

    private dipToPixel(value: number) {
        return value * (this.screen.devicePixelRatio || 1);
    }

    toInternal<
        T extends PublicRecorderAPI.Element | PublicRecorderAPI.ElementSelector
    >(
        element: T
    ): T extends PublicRecorderAPI.Element
        ? InternalRecorderAPI.Element
        : InternalRecorderAPI.ElementSelector {
        const { attributes, bounds, ...rest } = element;

        const mapBounds = () => {
            if (bounds) {
                const { x, y, width, height } = bounds;
                if (this.platform === 'android') {
                    return {
                        x: this.dipToPixel(x),
                        y: this.dipToPixel(y),
                        width: this.dipToPixel(width),
                        height: this.dipToPixel(height),
                    };
                } else {
                    return {
                        x: DataParser.toObjCNumber(x),
                        y: DataParser.toObjCNumber(y),
                        width: DataParser.toObjCNumber(width),
                        height: DataParser.toObjCNumber(height),
                    };
                }
            }
        };

        const mapAttributes = () => {
            if (attributes) {
                return Object.keys(attributes).reduce((acc, key) => {
                    if (this.platform === 'ios') {
                        switch (key) {
                            // convert boolean to '1' or '0'
                            case 'userInteractionEnabled':
                            case 'isHidden':
                                return {
                                    ...acc,
                                    [key]: attributes[key]
                                        ? '1'
                                        : ('0' as InternalRecorderAPI.BooleanString),
                                };
                        }
                    } else if (this.platform === 'android') {
                        // nothing yet
                    }

                    return {
                        ...acc,
                        [key]: attributes[key],
                    };
                }, {}) as InternalRecorderAPI.Element['attributes'];
            }
        };

        return cleanObject({
            ...rest,
            bounds: mapBounds(),
            attributes: mapAttributes(),
            // internal does not use accessibilityElements for playback, so just remove it
            accessibilityElements: undefined,
        }) as T extends PublicRecorderAPI.Element
            ? InternalRecorderAPI.Element
            : InternalRecorderAPI.ElementSelector;
    }

    toPublic<
        T extends
            | InternalRecorderAPI.Element
            | InternalRecorderAPI.ElementSelector
    >(
        element: T
    ): T extends InternalRecorderAPI.Element
        ? PublicRecorderAPI.Element
        : PublicRecorderAPI.ElementSelector {
        const { attributes, bounds, accessibilityElements, ...rest } = element;

        const mapBounds = (bounds: InternalRecorderAPI.ElementBounds) => {
            if (this.platform === 'android') {
                return {
                    x: this.pixelToDip(bounds.x as number),
                    y: this.pixelToDip(bounds.y as number),
                    width: this.pixelToDip(bounds.width as number),
                    height: this.pixelToDip(bounds.height as number),
                };
            } else {
                return {
                    x: DataParser.toNumber(bounds.x),
                    y: DataParser.toNumber(bounds.y),
                    width: DataParser.toNumber(bounds.width),
                    height: DataParser.toNumber(bounds.height),
                };
            }
        };

        const mapAttributes = (
            attributes:
                | InternalRecorderAPI.IOSElementAttributes
                | InternalRecorderAPI.IOSAccessibilityAttributes
        ) => {
            return Object.keys(attributes).reduce((acc, key) => {
                switch (key) {
                    // convert boolean to '1' or '0'
                    case 'userInteractionEnabled':
                    case 'isHidden':
                        return {
                            ...acc,
                            [key]: attributes[key] === '1' ? true : false,
                        };
                    default:
                        return {
                            ...acc,
                            [key]: attributes[key],
                        };
                }
            }, {}) as PublicRecorderAPI.Element['attributes'];
        };

        const mapAccessibilityElements = (
            accessibilityElements: InternalRecorderAPI.IOSAccessibilityElement[]
        ) => {
            return accessibilityElements.map((accessibilityEl) => {
                const { accessibilityFrame } = accessibilityEl;
                return {
                    ...mapAttributes(accessibilityEl),
                    accessibilityFrame: accessibilityFrame
                        ? mapBounds(accessibilityFrame)
                        : undefined,
                };
            });
        };

        return cleanObject({
            ...rest,
            bounds: bounds ? mapBounds(bounds) : undefined,
            attributes: attributes ? mapAttributes(attributes) : undefined,
            accessibilityElements: accessibilityElements
                ? mapAccessibilityElements(accessibilityElements)
                : undefined,
        }) as T extends InternalRecorderAPI.Element
            ? PublicRecorderAPI.Element
            : PublicRecorderAPI.ElementSelector;
    }
}

// prevents accidentally using window.screen that instead of this.screen
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let screen: never;
