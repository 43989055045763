import { Platform, ScreenBounds } from '../types/recorder/common';
import * as InternalRecorderAPI from '../types/recorder/internal';
import * as PublicRecorderAPI from '../types/recorder/public';
import { AppetizeApp } from '../types/app';

import { ActionMapper } from './action';
import { ElementMapper } from './element';

export class SessionEventMapper {
    platform?: Platform;
    screen?: ScreenBounds;
    app?: AppetizeApp;

    actionMapper: ActionMapper;
    elementMapper: ElementMapper;

    constructor({
        platform,
        screen,
        app,
    }: {
        platform: Platform;
        screen: ScreenBounds;
        app?: AppetizeApp;
    }) {
        this.app = app;
        this.platform = platform;
        this.screen = screen;

        this.actionMapper = new ActionMapper({
            platform,
            screen,
        });

        this.elementMapper = new ElementMapper({
            platform,
            screen,
        });
    }

    public toInternal(type: string, value: any) {
        switch (type) {
            case 'playAction': {
                const payload = value as {
                    id: string;
                    action: PublicRecorderAPI.Action;
                    timeout?: number;
                };
                const noMap = value.__noMap__; // for internal debug, not a public option

                const mappedAction = noMap
                    ? value.action
                    : this.actionMapper.toInternal(payload.action);

                return {
                    type,
                    value: {
                        ...payload,
                        action: mappedAction,
                    },
                };
            }
        }

        return { type, value };
    }

    public toPublic(type: string, value: any) {
        switch (type) {
            case 'debug':
                return {
                    type: 'log',
                    value: value,
                };
            case 'interceptResponse':
                return {
                    type: 'network',
                    value: {
                        type: 'response',
                        ...value,
                    },
                };
            case 'interceptRequest':
                return {
                    type: 'network',
                    value: {
                        type: 'request',
                        ...value,
                    },
                };

            case 'interceptError':
                return {
                    type: 'network',
                    value: {
                        type: 'error',
                        ...value,
                    },
                };

            case 'userError':
                return {
                    type: 'error',
                    value: value,
                };
            case 'userInteractionReceived':
                return {
                    type: 'interaction',
                    value: value,
                };
            case 'countdownWarning':
                return {
                    type: 'inactivityWarning',
                    value: value,
                };
            case 'h264Data':
                return {
                    type: 'video',
                    value: {
                        ...value,
                        codec: 'h264',
                    },
                };

            case 'frameData':
                return {
                    type: 'video',
                    value: {
                        ...value,
                        codec: 'jpeg',
                    },
                };
            case 'audioData': {
                return {
                    type: 'audio',
                    value: {
                        ...value,
                        codec: 'aac',
                    },
                };
            }

            case 'concurrentQueue':
                return {
                    type: 'queue',
                    value: {
                        type: 'concurrent',
                        name: value.name,
                        position: value.position,
                    },
                };
            case 'queue':
                return {
                    type: 'queue',
                    value: {
                        type: 'session',
                        position: value.position,
                    },
                };

            // xdoc events
            case 'orientationChanged':
                return {
                    type,
                    value,
                };
            case 'chromeDevToolsUrl':
                return {
                    type: 'networkInspectorUrl',
                    value,
                };

            // app recorder
            case 'recordedAction': {
                return {
                    type: 'action',
                    value: this.actionMapper.toPublic(value),
                };
            }
            case 'playbackFoundAndSent': {
                const v = value as InternalRecorderAPI.PlayActionResult;

                return {
                    type: 'playbackFoundAndSent',
                    value: {
                        ...v,
                        playback: {
                            ...v.playback,
                            action: v.playback?.action
                                ? this.actionMapper.toPublic(v.playback.action)
                                : undefined,
                        },
                        matchedElements: v.matchedElements?.map((e) => {
                            if (e) {
                                return this.elementMapper!.toPublic(e);
                            }
                        }),
                    },
                } as {
                    type: string;
                    value: PublicRecorderAPI.PlayActionResult;
                };
            }
            case 'playbackError': {
                const v = value as InternalRecorderAPI.PlayActionResult;

                return {
                    type: 'playbackError',
                    value: {
                        ...v,
                        playback: {
                            ...v.playback,
                            action: v.playback?.action
                                ? this.actionMapper.toPublic(v.playback.action)
                                : undefined,
                        },
                        matchedElements: v.matchedElements?.map((e) => {
                            if (e) {
                                return this.elementMapper!.toPublic(e);
                            }
                        }),
                    },
                } as {
                    type: string;
                    value: PublicRecorderAPI.PlayActionErrorResponse;
                };
            }
            case 'uiDump': {
                const appUi = value.ui ?? value.result;
                const springboardUi = value.springboard;

                const mapRecursive = (
                    element: InternalRecorderAPI.FullElement
                ): PublicRecorderAPI.FullElement => {
                    return {
                        ...this.elementMapper!.toPublic(element),
                        children: element.children?.map(mapRecursive),
                    };
                };

                const result: PublicRecorderAPI.AllUI = [];

                if (appUi) {
                    if (this.platform === 'ios') {
                        result.push({
                            type: 'app',
                            appId: this.app?.bundle,
                            children: appUi.map(mapRecursive),
                        });
                    } else {
                        // on android, everything is one tree. in the future they will separate.
                        result.push({
                            type: 'app',
                            children: appUi.map(mapRecursive),
                        });
                    }
                }

                if (springboardUi) {
                    result.push({
                        type: 'app',
                        appId: 'com.apple.springboard',
                        children: springboardUi.map(mapRecursive),
                    });
                }

                return {
                    type: 'uiDump',
                    value: result,
                };
            }
            // suppressed events
            case 'deleteEvent':
                return null;
        }

        return {
            type,
            value,
        };
    }
}

export class ClientEventMapper {
    public toInternal(type: string, value: any) {
        return { type, value };
    }

    public toPublic(type: string, value: any) {
        switch (type) {
            case 'userError':
                return {
                    type: 'error',
                    value: value,
                };
            case 'concurrentQueue':
                return {
                    type: 'queue',
                    value: {
                        type: 'concurrent',
                        name: value.name,
                        position: value.position,
                    },
                };
            case 'queue':
                return {
                    type: 'queue',
                    value: {
                        type: 'session',
                        position: value.position,
                    },
                };

            // xdoc events
            case 'deviceInfo':
            case 'sessionInfo':
            case 'sessionRequested':
                return {
                    type,
                    value,
                };
        }

        return {
            type,
            value,
        };
    }
}

// prevents accidentally using window.screen that instead of this.screen
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let screen: never;
