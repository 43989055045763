import { OperationalError } from '../../errors';
import { cleanObject } from '../../util';
import { DataParser, DataValidator } from '../parser';
import { Coordinates, Platform, ScreenBounds } from '../types/recorder/common';
import * as InternalRecorderAPI from '../types/recorder/internal';
import * as PublicRecorderAPI from '../types/recorder/public';

import { ElementMapper } from './element';

export class ActionMapper {
    platform: Platform;
    screen: ScreenBounds;

    elementMapper: ElementMapper;
    constructor({
        platform,
        screen,
    }: {
        platform: Platform;
        screen: ScreenBounds;
    }) {
        this.platform = platform;
        this.screen = screen;

        this.elementMapper = new ElementMapper({
            platform,
            screen,
        });
    }

    private pixelToDip(value: number) {
        return value / (this.screen.devicePixelRatio || 1);
    }

    private dipToPixel(value: number) {
        return value * (this.screen.devicePixelRatio || 1);
    }

    private getCoordinates(
        position: PublicRecorderAPI.Position,
        bounds: { width: number; height: number }
    ) {
        const x = DataParser.toPositionValue(position.x);
        const y = DataParser.toPositionValue(position.y);

        return {
            x: x * bounds.width,
            y: y * bounds.height,
        };
    }

    private getPosition(
        coordinates: Coordinates,
        bounds: { width: number; height: number }
    ) {
        return {
            x: coordinates.x / bounds.width,
            y: coordinates.y / bounds.height,
        };
    }

    private toInternalKey(value?: string) {
        switch (value) {
            case 'HOME':
                return 'home';
            case 'VOLUME_UP':
                return 'volumeUp';
            case 'VOLUME_DOWN':
                return 'volumeDown';
        }

        return value;
    }

    private toPublicKey(value?: string) {
        switch (value) {
            case 'home':
                return 'HOME';
            case 'volumeUp':
                return 'VOLUME_UP';
            case 'volumeDown':
                return 'VOLUME_DOWN';
        }

        return value;
    }

    toInternal(
        action: PublicRecorderAPI.Action | PublicRecorderAPI.RecordedAction
    ) {
        const map = () => {
            action = cleanObject(action);

            let element:
                | InternalRecorderAPI.Element
                | InternalRecorderAPI.ElementSelector
                | undefined;
            let coordinates: Coordinates | undefined;
            let localPosition: InternalRecorderAPI.Position | undefined;

            if ('element' in action && action.element) {
                element = this.elementMapper.toInternal(action.element);
            }

            if ('position' in action && action.position) {
                const x = DataParser.toPositionValue(action.position.x);
                const y = DataParser.toPositionValue(action.position.y);

                if (
                    !DataValidator.isValidNumber(x) ||
                    !DataValidator.isValidNumber(y)
                ) {
                    throw new OperationalError(
                        `Invalid position: (${action.position.x}, ${action.position.y}). Values must be a number or a percentage`
                    );
                }

                if (!DataValidator.isPositionWithinBounds(action.position)) {
                    if (typeof action.position.x === 'string') {
                        throw new Error(
                            `Invalid position: (${action.position.x}, ${action.position.y}) must be within (0%, 0%) and (100%, 100%)`
                        );
                    } else {
                        throw new Error(
                            `Invalid position: (${action.position.x}, ${action.position.y}) must be within (0, 0) and (1, 1)`
                        );
                    }
                }

                if (this.platform === 'android') {
                    coordinates = this.getCoordinates(action.position, {
                        width: this.dipToPixel(this.screen.width) - 1,
                        height: this.dipToPixel(this.screen.height) - 1,
                    });
                } else {
                    coordinates = this.getCoordinates(action.position, {
                        width: this.screen.width - 1,
                        height: this.screen.height - 1,
                    });
                }
            } else if ('coordinates' in action && action.coordinates) {
                if (
                    !DataValidator.isValidNumber(action.coordinates.x) ||
                    !DataValidator.isValidNumber(action.coordinates.y)
                ) {
                    throw new OperationalError(
                        `Invalid coordinates: (${action.coordinates.x}, ${action.coordinates.y}). Values must be a number`
                    );
                }

                if (
                    !DataValidator.isCoordinatesWithinBounds(
                        action.coordinates,
                        {
                            width: this.screen.width - 1,
                            height: this.screen.height - 1,
                        }
                    )
                ) {
                    throw new OperationalError(
                        `Invalid coordinates: (${action.coordinates.x}, ${
                            action.coordinates.y
                        }) exceed screen bounds (${this.screen.width - 1}, ${
                            this.screen.height - 1
                        })`
                    );
                }

                if (this.platform === 'android') {
                    coordinates = {
                        x: this.dipToPixel(action.coordinates.x),
                        y: this.dipToPixel(action.coordinates.y),
                    };
                } else {
                    coordinates = action.coordinates;
                }
            }

            if ('localPosition' in action && action.localPosition) {
                const x = DataParser.toPositionValue(action.localPosition.x);
                const y = DataParser.toPositionValue(action.localPosition.y);

                if (
                    !DataValidator.isValidNumber(x) ||
                    !DataValidator.isValidNumber(y)
                ) {
                    throw new OperationalError(
                        `Invalid localPosition: (${action.localPosition.x}, ${action.localPosition.y}). Values must be a number or a percentage`
                    );
                }

                // iOS sometimes reports localPosition values out of bounds. for the time being
                // we will disable this check.
                // if (!Validator.isPositionWithinBounds(action.localPosition)) {
                //     if (typeof action.localPosition.x === 'string') {
                //         throw new Error(
                //             `Invalid localPosition: (${action.localPosition.x}, ${action.localPosition.y}) must be within (0%, 0%) and (100%, 100%)`
                //         );
                //     } else {
                //         throw new Error(
                //             `Invalid localPosition: (${action.localPosition.x}, ${action.localPosition.y}) must be within (0, 0) and (1, 1)`
                //         );
                //     }
                // }

                localPosition = {
                    x,
                    y,
                };
            } else {
                if (element) {
                    localPosition = { x: 0.5, y: 0.5 };
                }
            }

            if ('duration' in action && action.duration) {
                if (!DataValidator.isValidNumber(action.duration)) {
                    throw new OperationalError(
                        `Invalid duration: ${action.duration}. Value must be a number`
                    );
                }
            }

            switch (action.type) {
                case 'tap': {
                    const { position, ...rest } = action;

                    return {
                        ...rest,
                        element,
                        localPosition,
                        coordinates,
                    } as InternalRecorderAPI.TapAction;
                }
                case 'swipe': {
                    const { position, ...rest } = action;

                    return {
                        ...rest,
                        element,
                        localPosition,
                        coordinates,
                        moves: action.moves.map((move) => {
                            if (this.platform === 'android') {
                                const { x, y } = this.getCoordinates(move, {
                                    width:
                                        this.dipToPixel(this.screen.width) - 1,
                                    height:
                                        this.dipToPixel(this.screen.height) - 1,
                                });
                                return {
                                    ...move,
                                    x,
                                    y,
                                };
                            } else {
                                const { x, y } = this.getCoordinates(move, {
                                    width: this.screen.width - 1,
                                    height: this.screen.height - 1,
                                });
                                return {
                                    ...move,
                                    x,
                                    y,
                                };
                            }
                        }),
                    } as InternalRecorderAPI.SwipeAction;
                }
                case 'keypress': {
                    const key = this.toInternalKey(action.key);
                    const character = this.toInternalKey(action.character);

                    return {
                        ...action,
                        key,
                        character,
                        shiftKey:
                            this.platform === 'ios'
                                ? DataParser.toNumber(action.shiftKey)
                                : action.shiftKey,
                    } as InternalRecorderAPI.KeypressAction;
                }
                case 'findElements': {
                    return {
                        ...action,
                        element,
                    } as InternalRecorderAPI.FindElementsAction;
                }
            }
            return action;
        };

        return cleanObject(map());
    }

    toPublic(
        action: InternalRecorderAPI.Action | InternalRecorderAPI.RecordedAction
    ): PublicRecorderAPI.Action | PublicRecorderAPI.RecordedAction {
        const map = () => {
            let element:
                | PublicRecorderAPI.Element
                | PublicRecorderAPI.ElementSelector
                | undefined;
            let coordinates: Coordinates | undefined;
            let position: PublicRecorderAPI.Position | undefined;
            let localPosition: PublicRecorderAPI.Position | undefined =
                // if this is a playback result payload, localPosition may be defined
                'localPosition' in action ? action.localPosition : undefined;

            if ('coordinates' in action && action.coordinates) {
                coordinates = {
                    x: this.pixelToDip(action.coordinates.x),
                    y: this.pixelToDip(action.coordinates.y),
                };

                position = this.getPosition(coordinates, {
                    width: this.screen.width - 1,
                    height: this.screen.height - 1,
                });
            }

            if ('element' in action && action.element) {
                element = this.elementMapper.toPublic(action.element);

                if (coordinates && element.bounds) {
                    localPosition = this.getPosition(
                        {
                            x: coordinates.x - element.bounds.x,
                            y: coordinates.y - element.bounds.y,
                        },
                        {
                            width: element.bounds.width,
                            height: element.bounds.height,
                        }
                    );
                }
            }

            switch (action.type) {
                case 'tap': {
                    return {
                        ...action,
                        coordinates,
                        element,
                        position,
                        localPosition,
                    } as PublicRecorderAPI.TapAction;
                }
                case 'swipe': {
                    return {
                        ...action,
                        coordinates,
                        element,
                        position,
                        localPosition,
                        moves: action.moves.map((move) => {
                            const { x, y } = this.getPosition(
                                {
                                    x: this.pixelToDip(move.x),
                                    y: this.pixelToDip(move.y),
                                },
                                {
                                    width: this.screen.width - 1,
                                    height: this.screen.height - 1,
                                }
                            );

                            return {
                                x,
                                y,
                                t: move.t,
                            };
                        }),
                    } as PublicRecorderAPI.SwipeAction;
                }

                case 'keypress': {
                    const key = this.toPublicKey(action.key);
                    const character = this.toPublicKey(action.character);

                    return {
                        ...action,
                        key,
                        character,
                        shiftKey:
                            typeof action.shiftKey === 'number'
                                ? DataParser.toBoolean(action.shiftKey)
                                : Boolean(action.shiftKey),
                    } as PublicRecorderAPI.KeypressAction;
                }
                case 'findElements': {
                    return {
                        ...action,
                        element,
                    } as PublicRecorderAPI.FindElementsAction;
                }
            }

            return action;
        };

        return cleanObject(map());
    }
}

// prevents accidentally using window.screen that instead of this.screen
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare let screen: never;
