export function uint8ArrayToString(uint8Arr: Uint8Array) {
    const length = uint8Arr.length;
    let result = '';
    for (let i = 0; i < length; i += 65535) {
        let addition = 65535;
        if (i + 65535 > length) {
            addition = length - i;
        }
        result += String.fromCharCode.apply(
            null,
            uint8Arr.subarray(i, i + addition) as unknown as number[]
        );
    }
    return result;
}

export function uint8ArrayToBase64(uint8Arr: Uint8Array, mimeType: string) {
    // if we're in node, use Buffer.from for faster conversion
    if (typeof window === 'undefined' && typeof Buffer !== 'undefined') {
        const str = Buffer.from(uint8Arr).toString('base64');
        return `data:${mimeType};base64,` + str;
    } else {
        const str = uint8ArrayToString(uint8Arr);
        const base64String = btoa(str);
        return `data:${mimeType};base64,` + base64String;
    }
}
