import { EventEmitter } from '../../EventEmitter';
import { SocketProtocol } from '../types/socket';
import { ClientEventMapper } from './event';

export class ClientSocketMapper extends EventEmitter implements SocketProtocol {
    private _socket: SocketProtocol;
    private eventMapper = new ClientEventMapper();

    constructor({ socket }: { socket: SocketProtocol }) {
        super();
        this._socket = socket;

        socket.on('*', ({ type, value }) => {
            const mapped = this.mapEmit(type, value);
            const suppressed = mapped === null;

            if (!suppressed) {
                this.emit(mapped.type, mapped.value);
                this.emit('*', mapped);
            }
        });
    }

    private mapEmit(type: string, value: any) {
        return this.eventMapper.toPublic(type, value);
    }

    private mapSend(type: string, value: any) {
        return this.eventMapper.toInternal(type, value);
    }

    send(event: string, data?: any): Promise<void> {
        const mapped = this.mapSend(event, data);

        return this._socket.send(mapped.type, mapped.value);
    }

    disconnect(): Promise<void> {
        return this._socket.disconnect();
    }
}
