import { Coordinates } from './types/recorder';
import * as InternalRecorderAPI from './types/recorder/internal';
import * as PublicRecorderAPI from './types/recorder/public';

export class DataValidator {
    /**
     * Helper error message to help migrate from old element format to new element format.
     */
    static isValidElementSelector(element: PublicRecorderAPI.ElementSelector) {
        if (typeof element !== 'object' || Array.isArray(element)) {
            throw new Error('Element must be an object');
        }

        const rootKeys = Object.keys(element);
        const knownAttributeFields = [
            'text',
            'accessibilityIdentifier',
            'accessibilityLabel',
            'resource-id',
            'content-desc',
            'class',
            'baseClass',
        ];

        const rootAttributes = intersect(rootKeys, knownAttributeFields);

        if (rootAttributes.length > 0) {
            const list = rootAttributes.map((v) => `'${v}'`).join(', ');
            throw new Error(
                `Element has invalid properties: ${list}. Did you mean to put these under 'attributes'?`
            );
        }

        return element;
    }

    static isCoordinatesWithinBounds(
        coordinates: Coordinates,
        bounds: { width: number; height: number }
    ) {
        if (coordinates.x < 0 || coordinates.x > bounds.width) {
            return false;
        }

        if (coordinates.y < 0 || coordinates.y > bounds.height) {
            return false;
        }

        return true;
    }

    static isPositionWithinBounds(
        position: InternalRecorderAPI.Position | PublicRecorderAPI.Position
    ) {
        const x = DataParser.toPositionValue(position.x);
        const y = DataParser.toPositionValue(position.y);

        if (x < 0 || x > 1) {
            return false;
        }

        if (y < 0 || y > 1) {
            return false;
        }

        return true;
    }

    static isValidNumber(value: number) {
        if (typeof value !== 'number') {
            return false;
        }

        if (isNaN(value)) {
            return false;
        }

        return true;
    }
}

export class DataParser {
    static toBoolean(value: number | null | undefined) {
        return value === 1 ? true : false;
    }

    static toNumber(
        value:
            | boolean
            | string
            | number
            | InternalRecorderAPI.ObjCNumber
            | undefined
    ) {
        if (typeof value === 'number') {
            return value;
        }

        if (typeof value === 'boolean' || value === undefined) {
            return value ? 1 : 0;
        }

        // handle obj-c number
        if (value === 'inf') {
            return Infinity;
        } else if (value === '-inf') {
            return -Infinity;
        }

        return parseFloat(value);
    }

    static toObjCNumber(value: number): InternalRecorderAPI.ObjCNumber {
        if (value === Infinity) {
            return 'inf';
        }

        if (value === -Infinity) {
            return '-inf';
        }

        return value;
    }

    /**
     * If value is a string ending with %, return the value as a number between 0 and 1.
     *
     * If value is a number betwen 0 and 1, return the value.
     */
    static toPositionValue(value: string | number): number {
        if (typeof value === 'string') {
            if (value.endsWith('%')) {
                return parseInt(value, 10) / 100;
            } else {
                throw new Error(
                    `Invalid position value: ${value}. Must be a number between 0 and 1, or a string ending with %`
                );
            }
        }

        return value;
    }
}

function intersect(a: any[], b: any[]) {
    return a.filter((v) => b.includes(v));
}
